import { Box, Typography } from "@mui/material";
import { wait } from "@testing-library/user-event/dist/utils";
import { HTTP_STATUS_SUCCESS } from "./App";

export const toEmptyStringIfNull = (str) => str === null ? "" : str;
 
export const LABEL_PRODUCT = "Produkt"
export const LABEL_FIRM = "Firma";
export const LABEL_FIRST_NAME = "Vorname"; 
export const LABEL_LAST_NAME = "Nachname";
export const LABEL_BIRTHDATE = "Geburtsjahr";
export const LABEL_PHONE_NUMBER = "Telefonnummer";
export const LABEL_EMAIL_ADDRESS = "Emailadresse";
export const LABEL_STREET = "Straße";
export const LABEL_HOUSE_NO = "Hausnummer";
export const LABEL_ZIP_CODE = "Postleitzahl";
export const LABEL_TOWN = "Ort";
export const LABEL_PUBLIC_COMMENT = "Kommentar";
export const LABEL_PRIVATE_COMMENT = "Privater Kommentar";
export const LABEL_PRICE = "Preis";
export const LABEL_COUNTRY = "Land"
export const LABEL_CONFIRM = "Bestätigen"
export const LABEL_IMPRINT = "Impressum"
export const LABEL_SAVE = "Speichern"
export const LABEL_DISABLE = "Deaktivieren"
export const LABEL_CLOSE = "Schließen"
export const LABEL_CONFIRMATION_LINK = "Bestätigungslink"

export const MSG_CHANGES_SAVED = "Änderungen gespeichert."
export const MSG_CHANGES_NOT_SAVED = "Änderungen nicht gespeichert."
export const MSG_ERROR_OCCURED = "Es trat ein Fehler auf. HTTP-Status-Code: "; 

/*
const PROTOCOL = "http"
const DOAMAIN = "localhost";
const PORT = "8080"
*/

const PROTOCOL = "https"
const DOAMAIN = "marketplace.p-space.io";
const PORT = "8443"


const APP_BASE_URL = "" 

export function buildUrl(urlFragment) {
    return PROTOCOL + "://" + DOAMAIN + ":" + PORT + "/" + APP_BASE_URL + urlFragment;
}

export function formatParam(param) {
    if (param == null) {
        return "";
    }
    else {
        return param;
    }
}

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`} 
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={Box}>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export async function handleResponse(promise, onSuccess, showMessage) {
    let body = null;
    try { 

        const response = await promise;
        if(response.status === HTTP_STATUS_SUCCESS) {
            let contentType = response.headers.get("content-type");
            if(contentType == "application/json") {
                body = await response.json()
            }
            else if(contentType == "application/pdf") {
                body = await response.blob()
            }
            else if(contentType == "text/plain;charset=UTF-8") {
                body = await response.text()
            } 
            onSuccess(body)
        } 
        else {     
            showMessage(MSG_ERROR_OCCURED + response.status) 
        } 
    }catch(e) {  
        showMessage(e.toString());
    } 
    return body;
}  

export async function handleValidationResponse(promise, onSuccess, onFailure, showMessage) {
    let body = null;
    try { 
        const response = await promise;
        if(response.status === HTTP_STATUS_SUCCESS) {
            if(response.headers.get("content-type") == "application/json") {
                body = await response.json()
            }
            else if(response.headers.get("content-type") == "text/plain;charset=UTF-8") {
                body = await response.text()
            }
            onSuccess(body)
        } 
        else {     
            showMessage(MSG_ERROR_OCCURED + response.status) 
            onFailure();
        } 
    }catch(e) {  
        showMessage(e.toString());
        onFailure();
    } 
    return body;
}  


export const dayCount = (month, year) => {
    if (month == 2) {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0) ? 29 : 28
    }
    else {
        if (month > 7) {
            month += 1 
        }
        return month % 2 == 0 ? 30 : 31
    }
}   